<template>
    <v-row no-gutters>
        <template v-for="(item, index) in editedItem" >
            <v-col data-testid="znap-dialog-input"
                v-if="!item.hide" :key="item.name"
                cols="12" sm="12" md="12" lg="12" xl="12"
            >
                <!-- item type === text -->
                <v-text-field v-if="!item.options && (item.type === 'VARCHAR' || item.type === 'TEXT') && item.name !== 'css' && item.name !== 'color' && item.name !== 'formula'"
                    v-model="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="text"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                />

                <!-- item columnType === check -->
                <v-switch v-if="!item.options && item.type === 'INT' && item.columnType === 'check'"
                    :label="item.label"
                    v-model="item.value"
                    @change="setSwitchValue($event, item)"
                />

                <!-- item columnType === radio -->
                <v-radio-group v-if="item.options && item.type === 'VARCHAR' && item.columnType === 'radio'"
                    hide-details class="mt-0 py-2"
                    @change="setRadioValue($event, item)"
                    v-model="item.value"
                >
                    <template v-slot:label>
                        <span class="text-subtitle-1">{{ isRequired(item) ? item.label + ' *' : item.label }}</span>
                    </template>
                    <template class="d-flex">
                        <v-radio v-for="option in item.options" :key="option.id"
                            class="d-flex align-center"
                            :ripple="false"
                            :value="option.text"
                        >
                            <template v-slot:label>
                                <span class="mr-4">{{ option.text }}</span>
                                <span class="text-caption">({{ option.description }})</span>
                            </template>
                        </v-radio>
                    </template>
                </v-radio-group>

                <!-- item name === css -->
                <v-text-field v-if="!item.options && (item.type === 'VARCHAR' || item.type === 'TEXT') && item.name === 'css'"
                    ref="cssInput"
                    v-model="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="text"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                    @focus="openStylesDialog(item)"
                />

                <!-- item name === formula -->
                <v-text-field v-if="!item.options && (item.type === 'VARCHAR' || item.type === 'TEXT') && item.name === 'formula'"
                    ref="formulaInput"
                    v-model="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="text"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                    @focus="openFormulaDialog()"
                />

                <!-- item type === int -->
                <v-text-field v-if="!item.options && item.type === 'INT' && item.columnType !== 'check'"
                    v-model.number="item.value"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="number"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                />

                <!-- item type === decimal -->
                <v-text-field v-if="!item.options && item.type === 'DECIMAL'"
                    v-model.number="item.value"
                    @change="formatFloat(item.value)"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    type="number"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :counter="item.length"
                    :maxlength="item.length"
                />

                <!-- item has options -->
                <v-autocomplete v-if="item.options && item.name !== 'color' && item.columnType !== 'radio'"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    v-model="item.value"
                    :items="getOptions(item)"
                    item-value="text"
                    return-object
                    @change="setSelectedOption(item.columnId, $event)"
                    :clearable="isRequired(item) ? false : true"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    :disabled="verifyOptionItemsLength(item)"
                />

                <v-autocomplete v-if="!item.options && item.name === 'color'"
                    :label="isRequired(item) ? item.label + ' *' : item.label"
                    :items="colors"
                    :value="getColor(item)"
                    item-value="text"
                    return-object
                    @change="setSelectedColorOption($event)"
                    :clearable="isRequired(item) ? false : true"
                    :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                />
                
                <!-- item type === date or item type === datetime -->
                <v-menu v-if="item.type === 'DATE' || item.type === 'DATETIME'"
                    :ref="'menu' + index"
                    v-model="datepicker[index]"
                    :close-on-content-click="false"
                    :return-value.sync="item.value"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="formatDate(item.value)"
                            :label="isRequired(item) ? item.label + ' *' : item.label"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                            :clearable="!isRequired(item)"
                            @click:clear="clearDatepicker(item.name, $event)"
                        ></v-text-field>
                    </template>

                    <v-date-picker
                        v-model="item.value"
                        no-title
                        scrollable
                        color="primary"
                    >
                        <v-spacer></v-spacer>

                        <!-- <v-btn text color="primary" @click="datepicker[index] = false">
                            Cancelar
                        </v-btn> -->
                        
                        <v-btn text color="primary" @click="saveSelectedDate(item.value, index)">
                            Alterar
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
        </template>

        <ZnapStylesDialog v-if="dialogStyles"
            :dialogStyles="dialogStyles"
            :editedRowStyles="editedRowStyles"
            @closeDialogStyles="closeSytlesDialog()"
            @saveEditedRowStyle="saveEditedRowStyle($event)"
        />

        <v-dialog v-model="dialogFormula" max-width="1000px" v-if="editedRowFormula">
            <ZnapFormulaDialog
                :editedRowFormula="editedRowFormula"
                :filterConditions="filterConditions"
                @closeDialogFormula="dialogFormula = false, editedRowFormula = null"
                @setEditedRowFormula="setEditedRowFormula($event)"
            />
        </v-dialog>
    </v-row>
</template>

<script>
import ZnapStylesDialog from '@/components/znap/ZnapStylesDialog'
import ZnapFormulaDialog from '@/components/znap/ZnapFormulaDialog'

export default {
    name: 'ZnapDialog',

    components: { ZnapStylesDialog, ZnapFormulaDialog },

    props: {
        editedItem: { type: Array, required: true },
        editedIndex: { type: Number, required: true },
        disabled: { type: Boolean, required: true },
        options: { type: Array, required: true },
        filterConditions: { type: Array, required: true },
    },

    data() {
        return {
            datepicker: [],
            date: '',
            selected: [],

            dialogStyles: false,
            editedRowStyles: null,

            dialogFormula: false,
            editedRowFormula: null,
        }
    },

    computed: {
        colors() {
            return [ 
                {color: 'yellow', text: 'Amarelo'},
                {color: 'blue', text: 'Azul'},
                {color: 'grey', text: 'Cinza'},
                {color: 'orange', text: 'Laranja'},
                {color: 'brown', text: 'Marrom'},
                {color: 'pink', text: 'Rosa'},
                {color: 'purple', text: 'Roxo'},
                {color: 'green', text: 'Verde'},
                {color: 'red', text: 'Vermelho'},
            ]
        }
    },

    watch: {
        editedIndex: {
            immediate: true,
            handler(val) {
                if (val > -1) {
                    this.options.forEach(o => {
                        if (o.filters) {
                            let item = this.editedItem.find(i => i.columnId === o.column)
                            if (item) {
                                let selection = o.items.find(i => i.text === item.value)
                                if (selection) this.populateFilterableFilter(o, selection)
                            }
                        }
                    })
                }
            }
        }
    },

    methods: {
        getColor(item) {
            let foundColor = this.colors.find(c => c.color === item.value)
            if (foundColor) {
                return foundColor.text
            }
        },

        openStylesDialog(item) {
            this.dialogStyles = true
            this.editedRowStyles = {
                id: this.editedIndex,
                css: item.value,
                row: null, 
            }
        },

        closeSytlesDialog() {
            this.dialogStyles = false
            this.editedRowStyles = null
            this.$refs.cssInput[0].blur()
        },

        setEditedRowStyle(event) {
            this.editedItem.find(item => item.name === 'css').value = event
            this.editedRowStyles = null
            this.dialogStyles = false
        },

        async saveEditedRowStyle(payload) {
            if (this.editedIndex > 0) {
                try {
                    const res = await this.$http.put('https://api.account.znaptech.com/account-group/edit-css', { ...payload })
                    if (res) {
                        this.setEditedRowStyle(payload.items[0].css)
                        this.dialogStyles = false
                        this.saving = false
                    }
                } catch (err) {
                    this.$fnError(err)
                    this.saving = false
                }
            } else {
                let css = ''
                payload.items.forEach(i => {
                    css += ` ${i.css}`
                })

                this.setEditedRowStyle(css)
            }

            this.dialogStyles = false
        },

        openFormulaDialog() {
            let editedRowFormula = {}

            this.editedItem.forEach(item => {
                editedRowFormula[item.name] = item.value
            })
            this.editedRowFormula = editedRowFormula

            this.dialogFormula = true
        },

        setEditedRowFormula(event) {
            let formulaIndex = this.editedItem.findIndex(item => item.name === 'formula')
            this.editedItem[formulaIndex].value = event.formula

            let variableIndex = this.editedItem.findIndex(item => item.name === 'variable')
            this.editedItem[variableIndex].value = event.variable

            this.editedRowFormula = null
            this.dialogFormula = false
        },

        getOptions(item) {
            let option = this.options.find(option => option.column === item.columnId)
            let items = option.items

            if (option.filteredBy) {
                items = option.filteredItems
            }

            return items
        },

        verifyOptionItemsLength(item) {
            let items = this.getOptions(item)
            return items.length ? false : true 
        },

        populateFilterableFilter(option, selection) {
            option.filters.forEach(async f => {
                let foundOption = this.options.find(o => o.column === f)

                let orderColumn = {}
                foundOption.filter?.orderColumn ? orderColumn = foundOption.filter : false

                let filter = {
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: option.column,
                            operator: '=',
                            value: selection.id
                        }
                    ]
                }
                
                if (orderColumn.orderColumn) {
                    filter.orderColumn = orderColumn.orderColumn
                    filter.textColumn = orderColumn.textColumn
                }

                try {
                    let res = await this.$http.post(foundOption.endpoint[0] + foundOption.endpoint[1] + '/list-options', { filter })
                    if (res) {
                        foundOption.filteredItems = res.data.rows
                    }
                } catch (err) {
                    this.$fnError(err)
                }
            })
        },
        
        setSelectedOption(columnId, e) {
            // verify if selected option filters another input field
            let found = this.options.find(o => o.column === columnId)
            if (found) {
                if (found.filters) {
                    found.filters.forEach(f => {
                        let foundOption = this.options.find(o => o.column === f)
                        let foundEditedItemColumn = this.editedItem.find(e => e.columnId === foundOption.column)
                        foundEditedItemColumn.value = null

                        this.populateFilterableFilter(found, e)
                    })
                }
            }

            this.editedItem.forEach((item, i) => {
                if (e) {
                    if (item.name === columnId) this.editedItem[i].value = e.id
                } else {
                    if (item.name === columnId) this.editedItem[i].value = null
                }
            })
        },

        setSelectedColorOption(e) {
            let i = this.editedItem.findIndex(item => item.name === 'color')
            if (e) {
                this.editedItem[i].value = e.color
            } else {
                this.editedItem[i].value = null
            }
        },

        clearDatepicker(itemName, e) {
            this.editedItem.forEach((item, i) => {
                if (e) {
                    if (item.name === itemName) this.editedItem[i].value = e.id
                } else {
                    if (item.name === itemName) this.editedItem[i].value = null
                }
            })
        },

        requiredValidation(item) {
            if (item && item.value !== null) {
                if ( item.value.length === 0 ) return true
            }
        },

        isRequired(item) {
            return item.rules.includes('required') ? true : false 
        },

        saveSelectedDate(date, index) {
            this.$refs['menu' + index][0].save(date)
            this.date = this.$options.filters.dateFilter(date)
        },

        formatDate(date) {
            if (date === undefined || date === null) {
                return ''
            } else {
                return this.$options.filters.dateFilter(date)
            }
        },

        formatFloat(float) {
            if (float === undefined || float === null) {
                return ''
            } else {
                return this.$options.filters.floatFilter(float)
            }
        },

        setSwitchValue(e, item) {
            if (e) {
                item.value = 1
            } else {
                item.value = 0
            }
        },

        setRadioValue(e, item) {
            let i = this.editedItem.findIndex(edited => edited.name === item.name)
            let selectedOption = item.options.find(o => o.text === e)
            this.editedItem[i].value = selectedOption.text
        }
    },
}
</script>

<style>

</style>